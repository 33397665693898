<template>
  <CustomToast />
  <PrimeVueLocaleHandler />
  <router-view v-slot="{ Component }">
    <component :is="Component" :key="$route.fullPath" />
  </router-view>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted } from "vue";
import { CustomToast } from "@cumulus/toast";
import { navigationShortcut } from "@cumulus/shortcut";
import { useAuth } from "@cumulus/event-bus";
navigationShortcut();

//Only running this code if the app is standalone
if (import.meta.env.VITE_APP_STANDALONE === "true") {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>

<style lang="scss">
/* Center content */
.c-layout-content {
  height: 100%;
  width: 100%;
  /* overflow: auto; //Bug - Do not add, it breaks sticky items */
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media (min-width: 992px) {
  .c-layout-content {
    padding-top: 0.5rem;
  }
}

.c-main-container {
  padding-top: 5rem;
  overflow: auto;
  position: relative;
  width: 100%;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  height: 100%;
}

@media (min-width: 992px) {
  .c-main-container .c-layout-margin {
    padding-top: 4rem;
  }
}

@media (min-width: 992px) {
  .c-auth .c-main-container {
    left: 240px;
    width: calc(100% - 240px);
  }
}

.c-guest .c-main-container {
  left: 0;
  width: initial;
  max-width: initial;
  padding-top: 3.8rem;
}

.c-layout-main-container {
  min-height: 100%;
}

.c-sidebar-mini .c-main-container {
  left: 0;
  width: 100%;
}

@media (min-width: 992px) {
  .c-sidebar-mini .c-main-container {
    left: 80px;
    width: calc(100% - 80px);
  }
}

.c-sidebar-show .c-main-container {
  transform: translate3d(280px, 0, 0);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
</style>
